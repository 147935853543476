import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { consumeService } from '@/api';
import { Paging } from '@/api/base';
import { OsTable } from '@/components';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { ChargeUnitEnum } from '@/resource/enum';
import { ConsumeItemList } from '@/resource/model';
import { messageError } from '@/utils';

@Component({
  components: {}
})
export default class ProductBrokenDetailDialog extends mixins(PagingMixin) {
  @PropSync('visible', { required: true }) public syncedVisible!: boolean;

  @Prop({ required: true }) public id!: number;

  /**
   * 报损记录表格配置
   */
  public brokenLogTableOption: OsTableOption<ConsumeItemList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
  };

  /**
   * 报损记录表格列配置
   */
  public brokenLogTableColumnOptions: Array<OsTableColumn<ConsumeItemList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'productName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCount',
      label: 'brokenLog.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionArea',
      label: 'brokenLog.area',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'calcUnit',
      label: 'brokenLog.priceUnit',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyUser',
      label: 'brokenLog.dutyUser',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'dutyReason',
      label: 'brokenLog.reason',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'brokenLog.createUserName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'brokenLog.createTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 报损记录分页参数
   */
  public brokenLogTablePaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  /**
   * 报损总记录数
   */
  public brokenLogTableTotalData = 0;

  public handleBrokenLogDialogOpen(): void {
    this.loadBrokenData();
  }

  /**
   * 报损记录分页切换回调
   */
  public brokenLogTablePagingData(): void {
    this.loadBrokenData();
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   */
  public getPriceUnit(unit: number): string {
    return 'chargeUnit.' + ChargeUnitEnum[unit];
  }

  /**
   * 报损记录数据查询
   */
  private loadBrokenData(): void {
    (this.$refs.brokenLogTableRef as OsTable).openLoading();
    consumeService
      .getConsumeRecord(this.id, this.brokenLogTablePaging)
      .then(res => {
        this.brokenLogTableOption.data = res.data;
        this.brokenLogTableTotalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.brokenLogTableRef as OsTable).closeLoading();
      });
  }
}
