import { messageError, showWarningConfirm, showWarningPrompt, translation } from '@/utils/index';
import { productionOrderProductService, productionOrderService } from '@/api';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { Component, Watch } from 'vue-property-decorator';
import ProductionOrderProductTable from './production-order-product-table/production-order-product-table.vue';
import MaterialClassStatistics from './material-class-statistics/material-class-statistics.vue';
import { ProductionOrderStatusEnum, ProductionOrderTypeEnum } from '@/resource/enum';
import { Message } from 'element-ui';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';
import { MessageBoxData, MessageBoxInputData } from 'element-ui/types/message-box';
import { ProductionOrderProductResource } from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'ProductionOrderDetail',
  components: {
    ProductionOrderProductTable,
    MaterialClassStatistics
  }
})
export default class ProductionOrderDetail extends mixins(PagingMixin) {
  /**
   * tab 激活页
   */
  public activeName = 'productList';

  /**
   * 订单对象
   */
  public order: ProductionOrderResource | null = null;

  /**
   * 订单ID
   */
  public orderId = Number(this.$route.query.orderId);

  /**
   * 物料汇总数据
   */
  public tableData: Array<ProductionOrderProductResource> = [];

  public pageVisible = true;

  public activated(): void {
    if (!isNaN(this.orderId) && this.orderId !== Number(this.$route.query.orderId)) {
      this.orderId = Number(this.$route.query.orderId);
      this.initOrder(this.orderId);
      this.loadData();
    }
  }

  public created(): void {
    if (!isNaN(this.orderId)) {
      this.initOrder(this.orderId);
      this.loadData();
    }
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusI18Key(status);
  }

  public getOrderTypeI18nKey(type: number): string {
    return 'order.' + ProductionOrderTypeEnum[type];
  }

  /**
   * 返回
   */
  public goBack(): void {
    this.$router.go(-1);
  }

  /**
   * 去新建变更单页面
   */
  public goCreateChangeLogPage(): void {
    this.$router.push({
      path: '/add-production-order-change',
      query: {
        orderId: this.orderId.toString()
      }
    });
  }

  /**
   * 去新建发货通知单页面
   */
  public goCreateShippingNoticePage(): void {
    this.$router.push({
      path: '/add-shipping-notice',
      query: {
        orderId: this.orderId.toString()
      }
    });
  }

  /**
   * 接受
   */
  public accept(): void {
    showWarningConfirm(translation('order.acceptTip'))
      .then(async () => {
        try {
          await productionOrderService.accept(this.orderId);
          this.order!.status = 3;
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 拒绝
   */
  public reject(): void {
    showWarningPrompt(translation('order.rejectTip'), translation('order.inputReason'), {
      inputErrorMessage: translation('order.inputReason')
    })
      .then(async (reason: MessageBoxData) => {
        await productionOrderService.reject(this.orderId, (reason as MessageBoxInputData).value);
        this.order!.status = 1;
        Message.success(translation('operationRes.operationSuccess'));
        this.$router.go(-1);
        TagsViewModule.DelView(this.$route);
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 判断是否可以取消接单
   * @return boolean
   */
  public canCancel(): boolean {
    return this.order!.status === 3 || this.order!.status === 4;
  }

  /**
   * 取消接单
   */
  public handleCancel(): void {
    showWarningConfirm(translation('order.cancelTip'))
      .then(async () => {
        try {
          await productionOrderService.cancel(this.orderId);
          this.order!.status = 2;
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 去门店详情
   * @param shopId 门店ID
   */
  public goShopDetail(shopId: string): void {
    this.$router.push({
      path: '/production-order-shop-detail',
      query: {
        shopId: shopId,
        orderId: this.order!.id.toString()
      }
    });
  }

  @Watch('$route.path', { immediate: true })
  public watchPath(newValue: string): void {
    this.pageVisible = newValue === '/production-order-detail' ? true : false;
  }

  /**
   * 初始化order数据
   */
  private initOrder(orderId: number): void {
    productionOrderService
      .getById(orderId)
      .then((res: ProductionOrderResource) => {
        this.order = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 获取物料汇总数据（接口与产品清单同一个接口）
   */
  private loadData(): void {
    const params = {
      orderId: this.orderId
    };
    this.paging.currentPage = 1;
    this.paging.showCount = 99999;

    productionOrderProductService
      .getList(
        params as ProductionOrderProductResource & {
          orderId: number;
        },
        this.paging
      )
      .then(res => {
        // 剔除已取消状态的数据
        this.tableData = res.data.filter(x => x.status !== -1);
      })
      .catch(error => {
        messageError(error);
      });
  }
}
