import { ProductionProductStatusEnum } from '@/resource/enum/production-product-status';
import { ProcessStatusEnum } from '@/resource/enum/process-status';
import { PagingMixin } from '@/mixins/paging';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { ProductionOrderProductResource } from '@/resource/model';
import { Query } from '@/api/base';
import { isNullOrUndefinedForBaseType, messageError, translation } from '@/utils';
import productionOrderProductService from '@/api/production-management/production-order-product';
import {
  ChargeUnitEnum,
  PlatformPrepressStatusEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProcessActionEnum,
  ProcessEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import ProductDetailDialog from '@/views/dialogs/product-detail-dialog/product-detail-dialog.vue';
import ProductBrokenDetailDialog from '@/views/dialogs/product-broken-detail-dialog/product-broken-detail-dialog.vue';
import { cloneDeep } from 'lodash';
import { minioService } from '@/api';
import { CustomColumnMixin } from '@/mixins/custom-column';

@Component({
  components: {
    ProductDetailDialog,
    ProductBrokenDetailDialog
  }
})
export default class ProductionOrderProductTable extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 订单ID
   */
  @Prop({ required: true }) public orderId!: number;
  /**
   * 表格引用
   */
  public $table!: OsTable;

  /**
   * 产品详情窗口显示标识
   */
  public detailDialogVisible = false;

  /**
   * 报损记录窗口显示标识
   */
  public brokenDetailDialogVisible = false;

  /**
   * 当前查看行数据
   */
  public viewRow: ProductionOrderProductResource | null = null;

  public viewRowId: number | null = null;

  /**
   * 预览图列表
   */
  public previewSrcList: Array<string> = [];

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderProductResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 查询表单对象
   */
  public queryForm: Query<{
    keywords: string;
    status: number | null;
    orderId: number | null;
  }> = {
    keywords: '',
    status: -2,
    orderId: null
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      align: 'center'
    },
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '210px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'prepressConfirmFile',
      label: 'orderProduct.prepressConfirmFile',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalArea',
      label: 'orderProduct.totalArea',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleWidth',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'orderProduct.visibleHeight',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeWidth',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'orderProduct.completeHeight',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressStatus',
      label: 'receipt.platformPrepressStatus',
      minWidth: '150px',
      formatter: (row: object): string => {
        if (!(row as ProductionOrderProductResource).makeUserName) {
          return translation(
            'platformPrepressStatus.' +
              PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]
          );
        }

        if (!PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]) {
          return `[${(row as ProductionOrderProductResource).makeUserName}]${translation(
            'common.unKnownStatus'
          )}`;
        }

        return `[${(row as ProductionOrderProductResource).makeUserName}]${translation(
          'platformPrepressStatus.' +
            PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]
        )}`;
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'orderProduct.prepressCrafts',
      minWidth: '180',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProductionOrderProductResource).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${
              PrepressChangeColorCraftsEnum[(row as ProductionOrderProductResource).prepressCrafts]
            }`
          );
        }
        return translation(
          `prepressLayoutCrafts.${
            PrepressLayoutCraftsEnum[(row as ProductionOrderProductResource).prepressCrafts]
          }`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'currentProcess',
      label: 'orderProduct.currentProcess',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishTime',
      label: 'orderProduct.finishTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionFlag',
      label: 'orderProduct.isConsumeRemake',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (isNullOrUndefinedForBaseType((row as ProductionOrderProductResource).attritionFlag)) {
          return '--';
        }
        return translation(
          `common.${(row as ProductionOrderProductResource).attritionFlag === 0 ? 'no' : 'yes'}`
        );
      }
    },
    {
      prop: 'dutyReason',
      label: 'brokenLog.reason',
      minWidth: '150',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionItemCode',
      label: 'brokenLog.brokenItem',
      minWidth: '150',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderProductResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'detail',
        type: 'text',
        label: 'orderProduct.brokenLog',
        icon: 'el-icon-tickets',
        permissionCode: 'production:report:listPage',
        handleClick: (row: ProductionOrderProductResource): void => {
          // 查看报损记录
          this.openBrokenLogDialog(row);
        }
      }
    ]
  };

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderProductResource> = [];

  public created(): void {
    this.loadData();
    this.initColumns(this.tableColumnOptions, 'productionOrderProductTable');
  }
  public mounted(): void {
    this.$table = this.$refs.tableRef1 as OsTable;
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getProcessStatusI18Key(status: ProcessStatusEnum): string {
    return productionOrderProductService.getProcessStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusClass(status);
  }

  /**
   * 获取当前工序
   * @param rowData 行数据
   */
  public getCurrentProcess(rowData: ProductionOrderProductResource): string {
    return productionOrderProductService.getProcessI18Key(rowData.processType);
  }

  /**
   * 获取工序国际化key
   */
  public getProcessTypeI18nKey(process: ProcessEnum): string {
    return productionOrderProductService.getProcessI18Key(process);
  }

  /**
   * 后去工序操作国际化key
   * @param action 操作类型
   * @returns
   */
  public getProcessActionI18nKey(action: ProcessActionEnum): string {
    return 'processAction.' + ProcessActionEnum[action];
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   * @returns
   */
  public getChargeUnitI18nKey(priceUnit: ChargeUnitEnum): string {
    return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  }

  /**
   * 打开订单产品详情
   * @param rowData 订单产品数据
   */
  public openProductDetailDialog(rowData: ProductionOrderProductResource): void {
    productionOrderProductService
      .getById(rowData.id)
      .then(res => {
        this.viewRow = res;
        this.detailDialogVisible = true;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {});
  }

  /**
   * 打开报损记录页面
   * @param rowData 订单产品数据
   */
  public openBrokenLogDialog(rowData: ProductionOrderProductResource): void {
    this.viewRowId = rowData.id;
    this.brokenDetailDialogVisible = true;
  }

  /**
   * 检索按钮
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderProductResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    this.tableOption.loading = true;
    const query = cloneDeep(this.queryForm);
    query.status = query.status === -2 ? null : query.status;
    query.orderId = this.orderId;

    productionOrderProductService
      .getList(query as ProductionOrderProductResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;

        this.initImageFullUrl();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async initImageFullUrl(): Promise<void> {
    await minioService.init();
    this.tableOption.data.forEach(async item => {
      if (item.prepressConfirmFile) {
        const canPrepressConfirmFilePreview = this.canPrepressConfirmFilePreview(item.prepressConfirmFile);

        const url = await minioService.getDownLoadPath(item.prepressConfirmFile);
        item.prepressConfirmFileFullUrl = url;

        if (canPrepressConfirmFilePreview) {
          this.previewSrcList.push(url);
        }
      }
    });
  }

  /**
   * 判断文件是否可预览
   * @param filePath 文件路径
   * @returns
   */
  private canPrepressConfirmFilePreview(filePath: string): boolean {
    const notAllowPreviewExtNameRegex = /^pdf/;
    const fileExtName = filePath.substring(filePath.lastIndexOf('.') + 1);
    if (notAllowPreviewExtNameRegex.test(fileExtName)) {
      return false;
    }
    return true;
  }

  private handleOpenWindowPreview(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  @Watch('orderId', { immediate: false })
  private handleOrderIdChanged(): void {
    this.reloadData();
  }
}
