import productionOrderProductService from '@/api/production-management/production-order-product';
import {
  ChargeUnitEnum,
  OperationTypeEnum,
  OrderProductTypeEnum,
  PlatformPrepressStatusEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProcessActionEnum,
  ProductionProductStatusEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { ProductionOrderProductResource } from '@/resource/model';
import { isNullOrUndefinedForBaseType, translation } from '@/utils';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'product-detail-dialog',
  components: {}
})
export default class ProductDetailDialog extends Vue {
  /**
   * 产品信息
   */
  @Prop({ required: true }) public product!: ProductionOrderProductResource;

  /**
   * 弹窗显示控制
   */
  @PropSync('visible', { required: true }) public syncedVisible!: boolean;
  /**
   * 产品详情tab激活页
   */
  public activeName = 'base';

  /**
   * 获取产品类型国际化key
   * @param type 产品类型
   * @returns
   */
  public getProductTypeI18nKey(type: number): string {
    return 'orderProductType.' + OrderProductTypeEnum[type];
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusI18Key(status);
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   * @returns
   */
  public getChargeUnitI18nKey(priceUnit: ChargeUnitEnum): string {
    return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  }

  /**
   * 获取工序国际化key
   * @param type 工序
   * @returns
   */
  public getProcessTypeI18nKey(operation: OperationTypeEnum): string {
    return 'operationTypeEnum.' + OperationTypeEnum[operation];
  }

  public formatterPrepressCrafts(data: ProductionOrderProductResource): string {
    if (data.itemType === ProjectItemTypeEnum.changeColor) {
      return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[data.prepressCrafts]}`);
    }
    return translation(`prepressLayoutCrafts.${PrepressLayoutCraftsEnum[data.prepressCrafts]}`);
  }

  /**
   * 获取工序操作国际化key
   * @param action 操作类型
   * @returns
   */
  public getProcessActionI18nKey(action: ProcessActionEnum): string {
    return 'processActionEnum.' + ProcessActionEnum[action];
  }

  public getPrepressStatusValue(): string {
    if (!this.product!.makeUserName) {
      return translation(
        'platformPrepressStatus.' + PlatformPrepressStatusEnum[this.product!.prepressStatus]
      );
    }

    if (!PlatformPrepressStatusEnum[this.product.prepressStatus]) {
      return `[${this.product.makeUserName}]${translation('common.unKnownStatus')}`;
    }

    return `[${this.product!.makeUserName}]${translation(
      'platformPrepressStatus.' + PlatformPrepressStatusEnum[this.product!.prepressStatus]
    )}`;
  }

  public flagName(flag: 1 | 0): string {
    return flag ? translation('orderProduct.flagOpen') : translation('orderProduct.flagClose');
  }

  public get isConsumeRemake(): string {
    if (isNullOrUndefinedForBaseType(this.product.attritionFlag)) {
      return '';
    }
    return translation(`common.${this.product.attritionFlag === 0 ? 'no' : 'yes'}`);
  }
}
