import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ProductionOrderProductResource } from '@/resource/model';
import Decimal from 'decimal.js';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class MaterialClassStatistics extends mixins(PagingMixin) {
  @Prop({
    type: Array,
    required: true,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<ProductionOrderProductResource>>;

  public tableOptions: OsTableOption<Partial<ProductionOrderProductResource>> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 2
      },
      {
        prop: 'totalArea',
        fixPlace: 6
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      type: 'index',
      prop: 'itemId',
      label: 'common.index',
      width: '80px',
      fixed: 'left'
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter(rowData: Object): String {
        return (rowData as ProductionOrderProductResource).count.toFixed(2);
      }
    },
    {
      prop: 'totalArea',
      label: 'orderProduct.totalArea',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter(rowData: Object): String {
        return (rowData as ProductionOrderProductResource).totalArea.toFixed(6);
      }
    }
  ];

  /**
   * 分类统计
   * @params data Array
   * @params key1 分类条件
   */
  public getGroup(data: Array<ProductionOrderProductResource>): Array<any> {
    const groups: any = [];
    data.forEach(c => {
      const value: any = c['platformProductName'];
      groups[value] = groups[value] || [];
      groups[value].push(c);
    });

    const result: any = [];
    Object.values(groups).forEach((cur: any) => {
      let count: number = 0; // 总数
      let totalArea: number = 0; // 总面积
      cur.forEach((item: ProductionOrderProductResource) => {
        count = Number(new Decimal(count).add(new Decimal(item.count)));
        totalArea = Number(new Decimal(totalArea).add(new Decimal(item.totalArea)));
      });
      result.push({
        platformProductName: cur[0].platformProductName,
        count: count,
        totalArea: totalArea
      });
    });
    return result;
  }

  @Watch('tableData', { deep: true, immediate: true })
  private handleDataList(value: Array<ProductionOrderProductResource>): void {
    this.tableOptions.data = this.getGroup(value);
  }
}
